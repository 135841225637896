// src/components/ProtectedRoute.jsx
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ element }) {
  const { auth } = useAuth();
  if (!auth.token || !auth.user) {
    return <Navigate to="/" replace />;
  }
  return element;
}

export default ProtectedRoute;
