// src/pages/ProfilePage.jsx
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import API from '../utils/axiosInstance';
import { Container, Typography, Button } from '@mui/material';
import FormInput from '../components/FormInput';

function ProfilePage() {
  const { auth, login } = useContext(AuthContext);

  // Call hooks unconditionally at the top:
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [message, setMessage] = useState('');
  const [hostRequested, setHostRequested] = useState(false);

  // Now we can safely check if user exists
  const user = auth.user;
  const token = auth.token;

  useEffect(() => {
    if (user) {
      // Once we know user is defined, set initial formData
      setFormData({ name: user.name, email: user.email });
    }
  }, [user]);

  if (!user) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom mt={4}>
          Your Profile
        </Typography>
        <Typography variant="body1">You are not logged in.</Typography>
      </Container>
    );
  }

  const { name, email } = formData;
  const { role, status } = user;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await API.put('/profile', formData);
      login(res.data.data, token);
      setMessage('Profile updated successfully.');
    } catch (err) {
      console.error(err.response?.data);
      setMessage(err.response?.data?.error || 'Update failed');
    }
  };

  const canRequestHost = (role === 'attendee' && status === 'approved' && !hostRequested);

  const requestHost = async () => {
    const res = await API.post('/auth/request-host');
    alert(res.data.message);
    setHostRequested(true);
    // Refresh user data
    const meRes = await API.get('/auth/me');
    login(meRes.data.data, token);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom mt={4}>Your Profile</Typography>
      {message && <Typography sx={{color:'green'}}>{message}</Typography>}
      <form onSubmit={onSubmit}>
        <FormInput name="name" label="Name" value={name} onChange={onChange} required />
        <FormInput name="email" label="Email" value={email} onChange={onChange} type="email" required />
        <Button variant="contained" color="primary" type="submit">Update Profile</Button>
      </form>

      {role === 'attendee' && status === 'approved' && (
        <>
          {!hostRequested ? (
            <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={requestHost}>
              Request Host
            </Button>
          ) : (
            <Button variant="contained" color="secondary" sx={{ mt: 2 }} disabled>
              Requested
            </Button>
          )}
        </>
      )}
    </Container>
  );
}

export default ProfilePage;
