// src/components/NavBar.jsx
import React from 'react';
import { AppBar, Toolbar, Typography, Box, Button, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

function NavBar() {
  const { auth, logout } = useContext(AuthContext);
  const isLoggedIn = !!auth.token;
  const user = auth.user;
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (!isLoggedIn) {
      navigate('/');
      return;
    }
    if (user.role === 'host' || user.role === 'admin') {
      navigate('/dashboard');
    } else {
      // attendee
      navigate('/calendar');
    }
  };

  if (!isLoggedIn) {
    // Logged out menu: Home, About, Features, Pricing, How It Works, Sign Up, Login
    return (
      <AppBar position="static" color="transparent" sx={{ boxShadow: 'none', borderBottom: '1px solid #ddd' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow:1, cursor:'pointer' }} onClick={handleLogoClick}>
            CoHost
          </Typography>
          <Box>
            <Button component={Link} to="/" sx={{ mr: 2 }}>Home</Button>
            <Button component={Link} to="/about" sx={{ mr: 2 }}>About</Button>
            <Button component={Link} to="/features" sx={{ mr: 2 }}>Features</Button>
            <Button component={Link} to="/pricing" sx={{ mr: 2 }}>Pricing</Button>
            <Button component={Link} to="/how-it-works" sx={{ mr: 2 }}>How It Works</Button>
            <Button component={Link} to="/signup" color="primary" variant="contained" sx={{ mr: 2 }}>Sign Up</Button>
            <Button component={Link} to="/login" color="primary" variant="outlined">Login</Button>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  // Logged in
  const isAdmin = user && user.role === 'admin';
  const isHost = user && user.role === 'host';
  const isAttendee = user && user.role === 'attendee';

  return (
    <AppBar position="static" color="transparent" sx={{ boxShadow:'none', borderBottom:'1px solid #ddd' }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow:1, cursor:'pointer' }} onClick={handleLogoClick}>
          CoHost
        </Typography>
        <Box>
          {/* Logged in: no About/Features/Pricing in nav. 
             Attendee: main link to /calendar, show Calendar link if desired
             Host/Admin: main link to /dashboard. 
             Everyone logged in can see Calendar */}
          <Button component={Link} to="/calendar" sx={{ mr:2 }}>Calendar</Button>

          <IconButton component={Link} to="/profile" sx={{ mr:2 }}>
            <PersonIcon />
          </IconButton>
          <IconButton component={Link} to="/settings" sx={{ mr:2 }}>
            <SettingsIcon />
          </IconButton>

          {isAdmin && (
            <Button component={Link} to="/admin-dashboard" sx={{ mr:2 }}>Admin Dashboard</Button>
          )}

          <Button onClick={logout} color="secondary" variant="contained">Logout</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
