// src/components/Footer.jsx
import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';

function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position:'fixed',
        bottom:16,
        right:16,
        zIndex:9999
      }}
    >
      <IconButton onClick={()=>navigate('/admin-login')}>
        <LockIcon />
      </IconButton>
    </Box>
  );
}

export default Footer;
