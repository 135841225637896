// src/pages/SettingsPage.jsx
import React from 'react';
import { Container, Typography } from '@mui/material';

function SettingsPage() {
  return (
    <Container>
      <Typography variant="h4" mt={4}>Settings</Typography>
      <Typography>Here you can manage account settings (Placeholder).</Typography>
    </Container>
  );
}

export default SettingsPage;
