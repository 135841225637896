// src/pages/AdminDashboardPage.jsx
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import API from '../utils/axiosInstance';
import { Container, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';

function AdminDashboardPage() {
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (auth.user && auth.user.role === 'admin') {
      fetchUsers();
    }
  }, [auth.user]);

  const fetchUsers = async () => {
    try {
      const res = await API.get('/admin/users');
      setUsers(res.data.users);
    } catch (err) {
      console.error(err);
    }
  };

  if (!auth.user) {
    return (
      <Container>
        <Typography variant="h4" mt={4}>Admin Dashboard</Typography>
        <Typography variant="body1">You are not logged in. Please <a href="/login">Login</a>.</Typography>
      </Container>
    );
  }

  if (auth.user.role !== 'admin') {
    return (
      <Container>
        <Typography variant="h4" mt={4}>Admin Dashboard</Typography>
        <Typography variant="body1">You are not authorized to view this page.</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" mt={4}>Admin Dashboard</Typography>
      <Typography variant="body1" paragraph>All Registered Users</Typography>
      <Button variant="outlined" sx={{mb:2}} onClick={fetchUsers}>Refresh</Button>
      {users.length === 0 ? (
        <Typography>No users found. Try registering a user or refreshing.</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(u => (
              <TableRow key={u._id}>
                <TableCell>{u.email}</TableCell>
                <TableCell>{u.role}</TableCell>
                <TableCell>{u.status}</TableCell>
                <TableCell>{u.name}</TableCell>
                <TableCell>{new Date(u.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Container>
  );
}

export default AdminDashboardPage;
