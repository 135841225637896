// src/pages/HomePage.jsx
import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent } from '@mui/material';
import { useAuth } from '../context/AuthContext';

// A stable event-related image (Unsplash) 
// Example: Photo by Krists Luhaers on Unsplash
// URL: https://images.unsplash.com/photo-1525395060476-5a1c37ac1b08?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80
const heroImageUrl = 'https://images.unsplash.com/photo-1525395060476-5a1c37ac1b08?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80';

function HomePage() {
  const { auth } = useAuth();
  const isLoggedIn = !!auth.token;

  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          height: { xs: '400px', md: '600px' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          backgroundImage: `url(${heroImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&:before': {
            content: '""',
            position:'absolute',
            top:0,left:0,right:0,bottom:0,
            background:'linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0.6) 100%)',
            zIndex:1,
          },
        }}
      >
        <Container sx={{ position:'relative', zIndex:2 }}>
          <Box textAlign="center">
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{ fontWeight:'bold', fontSize:{xs:'2.5rem', md:'3.5rem'} }}
            >
              {isLoggedIn ? 'Welcome Back to CoHost' : 'Elevate Your Event Experience'}
            </Typography>
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              sx={{ maxWidth:600, mx:'auto', mb:4 }}
            >
              {isLoggedIn
                ? 'Explore upcoming events and personalize your attendance. If interested in hosting, consider requesting host status.'
                : 'Discover events easily, sign up smoothly, and enjoy a hassle-free experience. Start as an attendee and learn more as you grow.'}
            </Typography>
            {!isLoggedIn && (
              <Button variant="contained" color="primary" size="large" href="/signup">
                Get Started
              </Button>
            )}
          </Box>
        </Container>
      </Box>

      <Container sx={{ mt:8 }}>
        {!isLoggedIn ? (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              A New Way to Experience Events
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
              CoHost focuses on simplicity. Browse events, sign up, and attend—no cluttered interfaces or overwhelming details upfront.
            </Typography>

            <Typography variant="h5" align="center" gutterBottom sx={{ mt:6 }}>
              Simple, Intuitive, Inclusive
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
              Enjoy basic attendance features as a free attendee. Interested in more? Once you gain experience and trust, request host status to unlock advanced tools.
            </Typography>

            <Box sx={{ textAlign:'center', mt:10, mb:10 }}>
              <Typography variant="h5" paragraph>
                Ready to Join?
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph sx={{ maxWidth:600, mx:'auto' }}>
                Sign up now to start attending events. Curious about what else is possible? Check out our <a href="/about">About</a>, <a href="/features">Features</a>, <a href="/pricing">Pricing</a>, and <a href="/how-it-works">How It Works</a> pages for a high-level overview.
              </Typography>
              <Button variant="outlined" color="primary" size="large" href="/signup">
                Sign Up Now
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Enhance Your Experience
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
              Already logged in, you can visit your calendar to see events or check your profile. If you’re an attendee, consider applying for host status to access advanced features once approved.
            </Typography>
          </>
        )}
      </Container>

      <Container sx={{ mt:10, mb:10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Key Highlights
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
          CoHost’s public-facing highlights keep it simple: attend events easily, and if desired, grow into a host role over time.
        </Typography>
        <Grid container spacing={4} sx={{ mt:4 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Effortless Discovery</Typography>
                <Typography variant="body2">
                  Find events easily without complex requirements. Just pick and attend.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Basic Attendance</Typography>
                <Typography variant="body2">
                  Enjoy simple, smooth attendance features right from the start, no extra steps.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Room to Grow</Typography>
                <Typography variant="body2">
                  Start as an attendee. Over time, request host status and unlock more functionality as you progress.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default HomePage;
