// src/pages/PricingPage.jsx
import React from 'react';
import { Container, Typography, Box, Card, CardContent, Button } from '@mui/material';

function PricingPage() {
  return (
    <Container sx={{ mt:4 }}>
      <Typography variant="h3" gutterBottom>Pricing</Typography>
      <Typography variant="body1" paragraph>
        CoHost offers simple pricing. Our attendee tier is always free, and a private host tier allows you to host small events with basic functionalities at no cost. Advanced host features are in premium tiers.
      </Typography>
      <Box sx={{display:'flex', flexWrap:'wrap', gap:2, mt:4}}>
        <Card sx={{flex:'1 1 300px'}}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Attendee (Free)</Typography>
            <Typography variant="body2" paragraph>
              - Access public events<br/>
              - Smooth check-ins<br/>
              - Basic attendee experience
            </Typography>
            <Button variant="contained" color="primary" href="/signup">Join as Attendee</Button>
          </CardContent>
        </Card>
        <Card sx={{flex:'1 1 300px'}}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Private Host (Free)</Typography>
            <Typography variant="body2" paragraph>
              - Create small events<br/>
              - Basic invites<br/>
              - Pending approval for full host features
            </Typography>
            <Button variant="contained" color="primary" href="/signup">Try Private Host</Button>
          </CardContent>
        </Card>
        <Card sx={{flex:'1 1 300px'}}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Premium Host (Paid)</Typography>
            <Typography variant="body2" paragraph>
              - Advanced attendance tracking<br/>
              - Metrics & insights<br/>
              - Promoter & security dashboards<br/>
              - Ideal for larger, professional events
            </Typography>
            <Button variant="outlined" color="primary" href="/signup">Upgrade to Premium</Button>
          </CardContent>
        </Card>
      </Box>
      <Typography variant="body1" paragraph mt={4}>
        Note: Detailed operational aspects remain reserved for approved hosts. Once you gain host approval or choose a premium tier, you’ll discover more tools and insights. Until then, enjoy a clean, attendee-friendly experience.
      </Typography>
    </Container>
  );
}

export default PricingPage;
