// src/pages/AboutPage.jsx
import React, { useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function AboutPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const user = auth.user;

  useEffect(() => {
    if (user) {
      // Logged in
      if (user.role === 'admin' || user.role === 'host') {
        navigate('/dashboard');
      } else {
        // attendee
        navigate('/calendar');
      }
    }
  }, [user, navigate]);

  return (
    <Container sx={{ mt:4 }}>
      <Typography variant="h3" gutterBottom>About CoHost</Typography>
      <Typography variant="body1" paragraph>
        CoHost provides a simple, attendee-focused experience. We keep details minimal up front so you can get started easily.
      </Typography>
      <Typography variant="body1" paragraph>
        Learn at your own pace, and once comfortable, consider exploring host options by applying when you're ready.
      </Typography>
    </Container>
  );
}

export default AboutPage;
