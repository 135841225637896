import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Grid } from '@mui/material';

function HowItWorks() {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);

  const correctPassword = 'HostSoHard'; // Replace with a real secure method

  const handleLogin = () => {
    if (enteredPassword === correctPassword) {
      setAccessGranted(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  if (!accessGranted) {
    return (
      <Container sx={{ mt: 10, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Restricted Access
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Please enter the password to view detailed information.
        </Typography>
        <Box sx={{ maxWidth: 400, mx: 'auto' }}>
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
            Enter
          </Button>
        </Box>
      </Container>
    );
  }

  // Once Access is Granted, Show Detailed Content
  return (
    <Container sx={{ mt: 8 }}>
      <Typography variant="h3" gutterBottom>
        How It Works
      </Typography>
      <Typography variant="body1" paragraph color="text.secondary">
        Below is a deeper look into the processes and features that power our platform. This information is intended for internal stakeholders, partners, and approved reviewers.
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Core Foundations
        </Typography>
        <Typography variant="body1" paragraph>
          Our platform integrates secure ID verification, attendee reputation scoring, and intelligent data handling to streamline event entry:
        </Typography>
        <ul>
          <li><b>ID Scanning & Verification:</b> We use OCR and integrated databases to confirm identities quickly and accurately.</li>
          <li><b>Attendee Reputation System:</b> Attendees earn trust by attending RSVP’d events, unlocking exclusive perks and making them more appealing to promoters and venues.</li>
          <li><b>Data & Analytics:</b> Organizers gain insights into attendance patterns, peak check-in times, repeat visitors, and much more, helping refine future events.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Promoter & Venue Tools
        </Typography>
        <Typography variant="body1" paragraph>
          Beyond the standard check-in flow, we offer a robust suite of tools for promoters and venues:
        </Typography>
        <ul>
          <li><b>Promoter List Management:</b> Promoters can create campaigns, register users on-the-fly, and assign tags for targeted invitations.</li>
          <li><b>Discovery Marketplace:</b> Users who opt-in appear in a special queue. Promoters/venues can discover these users, pay a small fee per attended event if they engage, and build curated lists based on demographics or interests.</li>
          <li><b>Custom Campaigns & Tags:</b> Create unique links or QR codes that auto-tag users as they sign up, enabling segmentation and VIP lists.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Monetization & Fees
        </Typography>
        <Typography variant="body1" paragraph>
          We balance a freemium approach with premium offerings:
        </Typography>
        <ul>
          <li><b>Ticketing Integration:</b> A portion of ticket fees covers the cost of discovery. If the per-person ticket margin is over $1, there’s no additional fee.</li>
          <li><b>Per Attendee Fee for Discovered Users:</b> If a discovered user attends a non-ticketed event or a low-margin ticketed event, a $1 fee applies to the organizer/promoter.</li>
          <li><b>Subscriptions & Campaigns:</b> Advanced analytics, multiple campaigns, and special targeting filters are available in paid tiers.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Privacy & Data Control
        </Typography>
        <Typography variant="body1" paragraph>
          Attendees always control what information is shared with promoters and venues. They can opt out of discovery, unfollow entities, or request data removal at any time. Compliance with data protection regulations and secure data handling is a top priority.
        </Typography>
      </Box>

      <Box sx={{ mt: 4, mb: 10 }}>
        <Typography variant="h5" gutterBottom>
          Long-Term Vision
        </Typography>
        <Typography variant="body1" paragraph>
          As the platform grows, we plan to integrate with external databases for verification, enhance predictive analytics, support dynamic pricing models, and form strategic partnerships with major event organizers and hospitality brands.
        </Typography>
      </Box>
    </Container>
  );
}

export default HowItWorks;
