// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import API from '../utils/axiosInstance';

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    user: null,
    token: localStorage.getItem('token') || null,
  });

  useEffect(() => {
    async function fetchUser() {
      if (auth.token && !auth.user) {
        try {
          const res = await API.get('/auth/me');
          setAuth((prev) => ({ ...prev, user: res.data.data }));
        } catch (err) {
          logout();
        }
      }
    }
    fetchUser();
  }, [auth.token, auth.user]);

  const login = (userData, token) => {
    setAuth({ user: userData, token });
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setAuth({ user: null, token: null });
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within AuthProvider');
  return context;
};
