// src/pages/FeaturesPage.jsx
import React from 'react';
import { Container, Typography, Box, Card, CardContent } from '@mui/material';

function FeaturesPage() {
  return (
    <Container sx={{ mt:4 }}>
      <Typography variant="h3" gutterBottom>Features</Typography>
      <Typography variant="body1" paragraph>
        CoHost’s publicly visible features focus on streamlined event browsing, easy sign-ups, and basic attendee experiences. Hosts can request approval to access more advanced functionalities.
      </Typography>
      <Box sx={{display:'flex', flexWrap:'wrap', gap:2, mt:2}}>
        <Card sx={{flex:'1 1 300px'}}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Easy Event Browsing</Typography>
            <Typography variant="body2">
              Find and register for events effortlessly, no complexity revealed upfront.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{flex:'1 1 300px'}}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Basic Attendance</Typography>
            <Typography variant="body2">
              Enjoy a straightforward check-in process at events without technical details exposed publicly.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Typography variant="body1" paragraph mt={4}>
        Interested in more? Attendees remain the core focus publicly. Host-level and premium features are accessible once host status is approved. Check our <a href="/pricing">Pricing</a> for host tiers.
      </Typography>
    </Container>
  );
}

export default FeaturesPage;
