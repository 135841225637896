import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SignUpPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'attendee'
  });

  const { name, email, password, role } = formData;
  const [error, setError] = useState('');

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const res = await fetch('/api/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, password, role })
      });

      const data = await res.json();
      if (!res.ok) {
        setError(data.error || 'Registration failed');
        return;
      }

      alert(`Account created for ${data.user.email} as ${data.user.role}. 
Your account is currently pending approval. We are in the initial stages of testing. 
Once your account is approved, you will have full access. For now, you can log in and have limited attendee functionality.`);
      navigate('/login');
    } catch (err) {
      console.error(err);
      setError('Server error');
    }
  };

  return (
    <Container sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        Get Early Access
      </Typography>
      <Typography variant="body1" paragraph>
        Sign up to be among the first to access our platform. Provide your details and choose your intended role.
      </Typography>

      {error && <Typography color="error" paragraph>{error}</Typography>}

      <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, mt: 4 }}>
        <TextField
          label="Name"
          name="name"
          type="text"
          fullWidth
          required
          value={name}
          onChange={onChange}
          sx={{ mb: 3 }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          fullWidth
          required
          value={email}
          onChange={onChange}
          sx={{ mb: 3 }}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          fullWidth
          required
          value={password}
          onChange={onChange}
          sx={{ mb: 3 }}
        />

        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel component="legend">I am interested in:</FormLabel>
          <RadioGroup
            name="role"
            value={role}
            onChange={onChange}
          >
            <FormControlLabel value="attendee" control={<Radio />} label="Attending events" />
            <FormControlLabel value="host" control={<Radio />} label="Hosting events" />
          </RadioGroup>
        </FormControl>

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Request Early Access
        </Button>
      </Box>
    </Container>
  );
}

export default SignUpPage;
