import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Container, Typography, Button, Box } from '@mui/material';
import FormInput from '../components/FormInput';
import { useNavigate, Link } from 'react-router-dom';

function LoginPage() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: ''});
  const [error, setError] = useState('');

  const { email, password } = formData;
  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password })
      });
      const data = await res.json();
      if(!res.ok) {
        setError(data.error || 'Login failed');
        return;
      }

      login(data.user, data.token);
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setError('Server error');
    }
  };

  const handleGoogleSignIn = () => {
    // Redirect to backend route that initiates Google OAuth flow
    window.location.href = '/api/auth/google';
  };

  const handleAppleSignIn = () => {
    // Redirect to backend route that initiates Apple OAuth flow
    window.location.href = '/api/auth/apple';
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom mt={4}>
        Login
      </Typography>
      {error && <Typography color="error" paragraph>{error}</Typography>}
      <form onSubmit={onSubmit}>
        <FormInput
          name="email"
          label="Email"
          value={email}
          onChange={onChange}
          type="email"
          required
        />
        <FormInput
          name="password"
          label="Password"
          value={password}
          onChange={onChange}
          type="password"
          required
        />
        <Button variant="contained" color="primary" type="submit">
          Login
        </Button>

        <Typography variant="body2" mt={2}>
          Don't have an account? <Link to="/signup">Register here</Link>
        </Typography>
      </form>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" gutterBottom>Or sign in with:</Typography>
        <Button variant="contained" color="secondary" sx={{ mr: 2 }} onClick={handleGoogleSignIn}>
          Sign in with Google
        </Button>
        <Button variant="contained" color="inherit" onClick={handleAppleSignIn}>
          Sign in with Apple
        </Button>
      </Box>
    </Container>
  );
}

export default LoginPage;
