// src/pages/Dashboard.jsx
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Container, Typography, Box, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../utils/axiosInstance';

function Dashboard() {
  const { auth, login } = useContext(AuthContext);
  const user = auth.user;
  const navigate = useNavigate();
  const location = useLocation();

  const [hostRequested, setHostRequested] = useState(false);

  useEffect(() => {
    // If OAuth success: token in URL
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      localStorage.setItem('token', token);
      API.get('/auth/me')
        .then(res => {
          login(res.data.data, token);
          navigate('/dashboard', {replace:true});
        })
        .catch(err => console.error(err));
    }

    if (user && user.role === 'host' && user.status === 'pending') {
      setHostRequested(true);
    }
  }, [location.search, user, login, navigate]);

  if (!user) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom mt={4}>Dashboard</Typography>
        <Typography variant="body1">You are not logged in.</Typography>
      </Container>
    );
  }

  const { role, status, email, name } = user;
  const isApproved = (status === 'approved');
  const isHost = (role === 'host');
  const isAttendee = (role === 'attendee');
  const isAdmin = (role === 'admin');

  const applyForHost = async () => {
    // call request-host endpoint
    try {
      await API.post('/auth/request-host');
      setHostRequested(true);
      // refresh user
      const meRes = await API.get('/auth/me');
      login(meRes.data.data, auth.token);
    } catch(err) {
      console.error(err);
    }
  };

  const cancelHostRequest = async () => {
    // call cancel host request endpoint
    try {
      await API.post('/auth/cancel-host');
      setHostRequested(false);
      // refresh user
      const meRes = await API.get('/auth/me');
      login(meRes.data.data, auth.token);
    } catch(err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom mt={4}>
        Dashboard
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome, {name} ({email})! Role: {role}, Status: {status}
      </Typography>

      {isAttendee && isApproved && (
        <Box sx={{ mt:2 }}>
          {!hostRequested ? (
            <Button variant="contained" color="primary" onClick={applyForHost}>
              Apply to be a Host
            </Button>
          ) : (
            <Box>
              <Button variant="contained" color="secondary" disabled sx={{mr:2}}>Requested</Button>
              <Button variant="outlined" color="primary" onClick={cancelHostRequest}>Cancel Application</Button>
            </Box>
          )}
        </Box>
      )}

      {/* Rest of your dashboard code showing features depending on role/status */}
    </Container>
  );
}

export default Dashboard;
